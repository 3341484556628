import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import Logo from './Logo';
import SearchForm from './SearchForm';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

export default function Header(){
    return (
    <Box
        sx = {{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            mt: 1,
            pb: 1,

            borderWidth:1,
            borderBottom: 1,
        }}
        justifyContent = 'space-evenly'
        >
        <Box sx = {{height: 20}}>
            <Link to="/"> 
                <Logo />
            </Link>
        </Box>
        <Box maxWidth={'40%'}>
            <SearchForm defaultLabel="Search another tag" />
        </Box>
        
        <AccountCircleOutlinedIcon />
    </Box>
    )
}