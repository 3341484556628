import { Button, Typography } from '@mui/material';
import { Container, Box } from '@mui/system';
import TextField from '@mui/material/TextField';
import LogoLight from '../assets/logo_light.svg'
import LogoDark from '../assets/logo_dark.svg'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom'

export default function Admin() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const logo = prefersDarkMode ? LogoDark : LogoLight;
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    navigate('/'+data.get('tagId'));
  }

    return (
      <>
        <Container component="main" maxWidth="xs">
            <Box
              sx = {{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
                <Box sx = {{
                  minWidth: '90%'
                }}>
                  <img  src={logo} alt="logo" width="100%"/>
                </Box>
                
                <Typography
                  sx = {{
                    marginTop: 8,
                    marginBottom: 5
                  }}
                >
                  Search for a dog's tag, all about them
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx= {{display: 'flex'}} justifyContent='space-evenly' width={'100%'}>
                  <TextField 
                    required
                    id="tagId"
                    label="Tag Id"
                    name="tagId"
                    autoFocus
                  />
                  <Button type="submit"> Search</Button>
                </Box>
                <Button sx={{
                  mt:3
                }}>
                  Account
                </Button>
            </Box>
        </Container>   
      </>
    );
  }
