import { Typography } from '@mui/material';

export default function VaxInfo({labelStyle = {ml:2}, title, vaxInfo}) {

    const smallText = {
        fontSize: 15,
        ml: 5
    }

    return(
        <>
            <Typography variant="h4">{title}</Typography>
                { vaxInfo.map((infoData) => 
                    <>
                        <Typography variant="h6" sx={labelStyle}>{infoData.label} {infoData.value} </Typography>
                        {infoData.expirationDate? <Typography sx={smallText}>Expires: {infoData.expirationDate}</Typography> : null }
                        {infoData.lastRecieved? <Typography sx={smallText}>Last Recived: {infoData.lastRecieved}</Typography> : null }
                        {infoData.dueDate? <Typography sx={smallText}>Due on: {infoData.dueDate}</Typography> : null }
                    </>
                )}
        </>
)
}