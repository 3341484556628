
import LogoLight from '../../assets/logo_light.svg'
import LogoDark from '../../assets/logo_dark.svg'
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Logo() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const logo = prefersDarkMode ? LogoDark : LogoLight;

    return <img  src={logo} alt="logo" height="100%"/>
}