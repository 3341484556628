import { Box } from '@mui/system';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom'


export default function SearchForm({defaultLabel, autoFocus}) {
    defaultLabel = defaultLabel?? "tag id"
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        navigate('/'+data.get('tagId'));
      }

    return <Box 
        component="form" 
        onSubmit={handleSubmit}
        >
    <TextField 
      required
      id="tagId"
      label={defaultLabel}
      name="tagId"
      autoFocus={autoFocus}
      size="small"
    />
  </Box>
}