import Header from '../components/shared/Header';
import { Outlet } from 'react-router-dom';

export default function NameCardPage() {
    return (
        <>
            <Header />
            <Outlet />
        </>      
    );
}