import localforage from "localforage";
import { matchSorter } from "match-sorter";
import sortBy from "sort-by";
localforage.setItem("namecards", [
    {
        id: "bori",
        basicInfo : {
            name :  "Bori",
            breed : "Miniature Golden Retriever",
            sex : "Female",
            color : "Cream",
            spay : "Done",
            dob : "12/16/2020",
            contact : "1-310-745-3637",
            condition : "N/A",
        },
        phone: "+13107453637",
        tags: [
            {label: "Chicago City Clark", value: "WLX33ZX", expirationDate: "11/09/2025"},
            {label: "Rabies Vaccination", value: "A150066", expirationDate: "3/21/2022"},
        ],
        vaccines: [
            {label: "Leptospirosis", lastRecieved: "03/08/2022", dueDate: "30/08/2023"},
            {label: "Bordetella", lastRecieved: "03/08/2022", dueDate: "30/08/2023"},
            {label: "Rabies", lastRecieved: "03/08/2022", dueDate: "30/08/2025"},
            {label: "DAPP", lastRecieved: "03/08/2022", dueDate: "30/08/2025"}
        ]
    },
    {
        id: "XSs",
        basicInfo : {
            name :  "Sophie",
            breed : "Golden Retriever",
            sex : "Female",
            color : "Cream",
            spay : "Done",
            dob : "12/16/2020",
            contact : "1-310-745-3637",
            condition : "N/A",
        },
        phone: "+13107453637",
        tags: [
            {label: "Chicago City Clark", value: "WLX33ZX", expirationDate: "11/09/2025"},
            {label: "Rabies Vaccination", value: "A150066", expirationDate: "3/21/2022"},
        ],
        vaccines: [
            {label: "Leptospirosis", lastRecieved: "03/08/2022", dueDate: "30/08/2023"},
            {label: "Bordetella", lastRecieved: "03/08/2022", dueDate: "30/08/2023"},
            {label: "Rabies", lastRecieved: "03/08/2022", dueDate: "30/08/2025"},
            {label: "DAPP", lastRecieved: "03/08/2022", dueDate: "30/08/2025"}
        ]
    }
])
export async function getNamecards(query) {
  await fakeNetwork(`getNamecards:${query}`);
  let namecards = await localforage.getItem("namecards");
  if (!namecards) namecards = {};
  if (query) {

    namecards = matchSorter(namecards, query, { keys: ["first", "last"] });
  }
  return namecards.sort(sortBy("last", "createdAt"));
}

export async function createNamecard() {
  await fakeNetwork();
  let id = Math.random().toString(36).substring(2, 9);
  let namecard = { id, createdAt: Date.now() };
  let namecards = await getNamecards();
  namecards.unshift(namecard);
  await set(namecards);
  return namecard;
}

export async function getNamecard(id) {
  await fakeNetwork(`namecard:${id}`);
  let namecards = await localforage.getItem("namecards");
  let namecard = namecards.find(namecard => namecard.id === id);
  return namecard ?? null;
}

export async function updateNamecard(id, updates) {
  await fakeNetwork();
  let namecards = await localforage.getItem("namecards");
  let namecard = namecards.find(namecard => namecard.id === id);
  if (!namecard) throw new Error("No namecard found for", id);
  Object.assign(namecard, updates);
  await set(namecards);
  return namecard;
}

export async function deleteNamecard(id) {
  let namecards = await localforage.getItem("namecards");
  let index = namecards.findIndex(namecard => namecard.id === id);
  if (index > -1) {
    namecards.splice(index, 1);
    await set(namecards);
    return true;
  }
  return false;
}

function set(namecards) {
  return localforage.setItem("namecards", namecards);
}

// fake a cache so we don't slow down stuff we've already seen
let fakeCache = {};

async function fakeNetwork(key) {
  if (!key) {
    fakeCache = {};
  }


  if (fakeCache[key]) {
    return;
  }

  fakeCache[key] = true;
  return new Promise(res => {
    setTimeout(res, Math.random() * 800);
  });
}