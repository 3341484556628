import { Button, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { useLoaderData } from "react-router-dom";
import { getNamecard } from "../../data/database";
import VaxInfo from "./VaxInfo";

export async function loader({ params }) {
    return getNamecard(params.namecardId);
  }

export default function Namecard() {
    const mockData = useLoaderData();
    
    const handleCall = () => {window.open("tel:"+mockData.phone);}

    const labelsSx = {
        ml: 2
    }

    const basicInfos = mockData.basicInfo
    
    const basicInfo = (text, data) => data ? <Typography sx={labelsSx}>{text}: {data}</Typography> : null

    const informationItems =  
        <>
            <Typography variant="h4">Basic Informations</Typography>
            {basicInfo("Breed", basicInfos.breed)}
            {basicInfo("Sex", basicInfos.sex)}
            {basicInfo("Color", basicInfos.color)}
            {basicInfo("Spay", basicInfos.dob)}
            {basicInfo("Date of Birt", basicInfos.contact)}
            {basicInfo("Contact", basicInfos.spay)}
            {basicInfo("Other Conditions", basicInfos.condition)}
        </>

    return (
        <Container component="main" sx={{pt:1}}>
            <Box
                sx = {{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
                }}
            >
                <Box>
                    <Typography variant="h2">{mockData.basicInfo.name}</Typography>
                        {informationItems}
                    <VaxInfo title="Tag Information" vaxInfo={mockData.tags} />
                    <VaxInfo title="Vaccination Informations" vaxInfo={mockData.vaccines} />
                    <Button onClick={handleCall} >Call {mockData.phone}</Button>
                </Box>
            </Box>
        </Container>  
    )
    
}