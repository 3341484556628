import React from 'react';
import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import FrontPage from "./routes/FrontPage";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom"
import useMediaQuery from '@mui/material/useMediaQuery';
import ErrorPage from './Error';
import Test from './routes/Test';
import { loader as testLoader } from './routes/Test';
import NamecardError from './routes/NamecardError';
import NamecardPage from './routes/NamecardPage';
import Namecard, { loader as namecardLoader } from './components/namecardPage/Namecard';
import Admin from './routes/Admin';

export function App() {
const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
const theme = React.useMemo(
  () =>
    createTheme({
      palette: {
        mode: prefersDarkMode ? 'dark' : 'light',
        ...(prefersDarkMode ? 
          {//dark custom style
            background: {
              default: "#121222",
              paper: "#121222"
            }
          } : { //light custom style

          }
        )
      },
    }),
  [prefersDarkMode],
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <FrontPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/admin",
    element: <Admin />,
  },
  {
    path: "/namecard/:namecardId",
    element: <NamecardPage />,
    children: [
      {
        path: "/namecard/:namecardId",
        element: <Namecard />,
        errorElement: <NamecardError />,
        loader: namecardLoader
      },
      // {
      //   path: "namecard/add",
      //   element: <NamecardAdd />
      // }
    ]
  },
  {
    path: "/:testId",
    element: <Test />,
    loader: testLoader
  }
]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <RouterProvider router={router}/>
      </CssBaseline>
    </ThemeProvider>
  );
};

export default App;
